import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

/* Custom: import from custom-components */
// import LoginDialog from "./LoginDialog";
import LoginDialog from "custom-components/shared/Dialogs/LoginDialog/LoginDialog";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseLoginDialog: PropTypes.func.isRequired,
};

const defaultProps = {
  showModal: false,
};

export const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language,
    projectId: state.projectId,
  };
};

/**
 * Pop-up Modal to render the login prompt
 */
export class LoginDialogContainer extends Component {
  render() {
    return (
      <LoginDialog
        showModal={this.props.showModal}
        handleCloseLoginDialog={this.props.handleCloseLoginDialog}
        language={this.props.language}
        projectId={this.props.projectId}
      />
    );
  }
}

LoginDialogContainer.propTypes = propTypes;
LoginDialogContainer.defaultProps = defaultProps;

export default connect(mapStateToProps)(LoginDialogContainer);

import React from "react";

import { REGISTER, PROJECT_REGISTER } from "App/Routes";
import Link from "components/shared/Link/Link";
import LoginDialog from "components/shared/Dialogs/LoginDialog/LoginDialog";

import Modal from "react-modal";

import localize from "lang/localize";

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class GametizeLoginDialog extends LoginDialog {
  render() {
    return (
      <Modal
        isOpen={this.state.showModal}
        contentLabel={localize("nav_bar_title_login_text", this.props.language)}
        onRequestClose={this.props.handleCloseLoginDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog login-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <i className="far fa-grin-wink font-size-xxxl"></i>
          </div>
          <div className="dialog-body">
            {localize("dialog_register_text", this.props.language)}
            <Link
              to={
                this.props.projectId
                  ? PROJECT_REGISTER.format(this.props.projectId)
                  : REGISTER
              }
            >
              {localize("dialog_register_text_end", this.props.language)}
            </Link>
          </div>
          {/* Custom: double row buttons on mobile to accommodate long text */}
          <div className="dialog-footer">
            <div className="textright hide-below-sm">
              <button
                className="button inline"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleCloseLoginDialog();
                }}
              >
                {localize("web_confirm_cancel_text", this.props.language)}
              </button>
              <Link to={this.renderLoginLink()} className="button inline cta">
                {localize(
                  "gametize_sign_in_guest_player_text",
                  this.props.language,
                )}
              </Link>
            </div>
            <div className="hide-from-sm">
              <div className="bottommargin-10">
                <Link
                  to={this.renderLoginLink()}
                  className="button cta fullwidth"
                >
                  {localize(
                    "gametize_sign_in_guest_player_text",
                    this.props.language,
                  )}
                </Link>
              </div>
              <div>
                <button
                  className="button fullwidth"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleCloseLoginDialog();
                  }}
                >
                  {localize("web_confirm_cancel_text", this.props.language)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default GametizeLoginDialog;

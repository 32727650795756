import React from "react";

import Link from "components/shared/Link/Link";
import Topbar from "components/Topbar/Topbar";

import {
  ENABLE_LANGUAGE_SELECTION,
  ENABLE_LOGIN_HOME,
  USE_LOGIN_HOME_TEMPLATE_2,
} from "config";
import localize from "lang/localize";
import urlServices from "services/urlServices";

class GametizeTopbar extends Topbar {
  /**
   * Custom for Gametize:
   * Add custom class for environment
   */
  renderTopbar() {
    /* No back button */
    if (
      (ENABLE_LOGIN_HOME && this.props.state === "login-home") ||
      (!ENABLE_LOGIN_HOME && this.props.state === "login")
    ) {
      return (
        <div
          className={
            "topbar topbarbg pure-g fixed fullwidth topbar-" +
            process.env.REACT_APP_TYPE
          }
        >
          <div className="pure-u-sm-7-24" />
          <div className="pure-u-sm-1-24" />
          <div className="pure-u-13-24 pure-u-sm-8-24">
            <div
              className="title-wrap text-truncated-parent not-logged-in"
              dangerouslySetInnerHTML={{ __html: this.renderTopbarTitle() }}
            />
          </div>
          <div className="pure-u-1-24 hide-from-sm" />
          <div className="pure-u-10-24 pure-u-sm-8-24">
            {this.renderRightTopbar()}
          </div>
        </div>
      );
    } else {
      /* With back button */
      return (
        <div
          className={
            "topbar topbarbg pure-g fixed fullwidth topbar-" +
            process.env.REACT_APP_TYPE
          }
        >
          <div className="pure-u-5-24 pure-u-sm-4-24 pure-u-md-6-24 relative">
            {this.renderBack()}
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-12-24 pure-u-sm-14-24 pure-u-md-10-24">
            <div
              className="title-wrap text-truncated-parent"
              dangerouslySetInnerHTML={{ __html: this.renderTopbarTitle() }}
            />
          </div>
          <div className="pure-u-6-24 pure-u-sm-5-24 pure-u-md-7-24">
            {this.renderRightTopbar()}
          </div>
        </div>
      );
    }
  }

  /*
    Custom for Gametize:
    (1) "search-projects"
  */
  renderBackText() {
    let projectTitle = this.props.project ? this.props.project.title : "";
    let topicTitle = this.props.topic ? this.props.topic.title : "";

    switch (this.props.state) {
      case "login":
        if (ENABLE_LOGIN_HOME) {
          return localize("icon_home", this.props.language);
        } else if (this.props.state === "login-secondary") {
          return localize("nav_bar_title_login_text", this.props.language);
        } else {
          return null;
        }
      case "login-secondary":
        if (ENABLE_LOGIN_HOME && USE_LOGIN_HOME_TEMPLATE_2) {
          return localize("icon_home", this.props.language);
        } else if (this.props.state === "login-secondary") {
          return localize("nav_bar_title_login_text", this.props.language);
        } else {
          return null;
        }
      case "reset-password":
      case "post-login":
        return localize("nav_bar_title_login_text", this.props.language);
      case "projects-list":
      case "search-projects":
        return localize("nav_bar_title_games_home_text", this.props.language);
      case "projecthome":
        if (this.props.isEmbeddedProject) {
          return null;
        } else if (urlServices.getUrlParamValueByKey("prevUrl")) {
          return localize("back_button_back_text", this.props.language);
        } else {
          return localize("nav_bar_title_games_home_text", this.props.language);
        }
      case "topic":
      case "topic-category":
        return this.props.project && this.props.project.title
          ? projectTitle
          : localize("back_button_back_text", this.props.language);
      case "challenge":
      case "challenge-category":
        return this.props.topic && this.props.topic.title
          ? topicTitle
          : localize("back_button_back_text", this.props.language);
      case "challenge-comments":
        return this.props.challenge && this.props.challenge.title
          ? this.props.challenge.title
          : localize("back_button_back_text", this.props.language);
      case "discussions-posts":
        if (!this.props.board) {
          return localize("back_button_back_text", this.props.language);
        }

        return this.props.project && this.props.project.title
          ? this.props.project.title
          : localize("back_button_back_text", this.props.language);
      case "home":
      case "gametize_login":
        return localize("gametize_button_back_to_home", this.props.language);
      /*
        Custom: No "home-single" nor "home-multi" option in app.gametize.com,
        so these cases are removed (as opposed to web-blank)
      */
      case "login-tour":
        return null;
      default:
        return localize("back_button_back_text", this.props.language);
    }
  }

  renderBack() {
    /* Special case: at topic page - you are a project administrator, but not a project player */
    if (
      (this.props.state === "topic" || this.props.state === "topic-category") &&
      !this.props.projectId
    ) {
      return null;
    } else if (this.props.state === "login-tour") {
      /*
        Custom: no need for back button in login tour page.
        home-multi and home-single do not exist in app.gametize.com,
        and "home" is handled below with "gametize_login"
      */
      return null;
    } else if (
      this.props.state === "projecthome" &&
      this.props.isEmbeddedProject
    ) {
      /* For embedded projects, no way to go back home */
      return null;
    } else if (
      this.props.backLink !== "" &&
      (this.props.state === "gametize_login" || this.props.state === "home")
    ) {
      /* Custom: for gametize login and home, we use <a> instead of <Link> so that we do not have to manage relative URLs */
      return (
        <div className="back-button-wrap">
          {/* Use default Back text for mobile sizes */}
          <a href={this.props.backLink} className="link hide-from-md">
            <span className="back-button-text text-truncated">
              <i className="fas fa-angle-left" aria-hidden="true" />
              {localize("back_button_back_text", this.props.language)}
            </span>
          </a>
          {/* Use renderBackText() for desktop sizes */}
          <a href={this.props.backLink} className="link hide-below-md">
            <span className="back-button-text text-truncated">
              <i className="fas fa-angle-left" aria-hidden="true" />
              {this.renderBackText()}
            </span>
          </a>
        </div>
      );
    } else if (this.props.backLink !== "") {
      /*
        For cases that involve routing
      */
      return (
        <div className="back-button-wrap">
          {/* Use default Back text for mobile sizes */}
          <Link to={this.props.backLink} className="hide-from-md">
            <span className="back-button-text text-truncated">
              <i className="fas fa-angle-left" aria-hidden="true" />
              {localize("back_button_back_text", this.props.language)}
            </span>
          </Link>
          {/* Use renderBackText() for desktop sizes */}
          <Link to={this.props.backLink} className="hide-below-md">
            <span className="back-button-text text-truncated">
              <i className="fas fa-angle-left" aria-hidden="true" />
              {this.renderBackText()}
            </span>
          </Link>
        </div>
      );
    } else {
      /*
      For cases that involve simulated browser back button
    */

      if (window.history.length <= 1) {
        return null; // Remove back button if no history
      }

      return (
        <div className="back-button-wrap">
          <span
            className="back-button-text text-truncated back-button-history-text"
            onClick={this.props.handleBack}
          >
            <i className="fas fa-angle-left" aria-hidden="true" />
            {this.renderBackText()}
          </span>
        </div>
      );
    }
  }

  renderRightTopbar() {
    /* Custom: Topbar menu should not render for "Gametize Login" states. */
    /* No menu icon on login and register pages */
    return (
      <div className="icons-wrap floatright">
        {ENABLE_LANGUAGE_SELECTION && (
          <div
            className="language-icon cursor-pointer"
            id="languageIcon"
            onClick={this.props.handleOpenLanguageSelectionDialog}
          >
            <i className="fas fa-globe" />
            <div className="language-badge floatright">
              {this.renderLangText()}
            </div>
          </div>
        )}
        {this.props.state !== "login" &&
          this.props.state !== "gametize_login" &&
          this.props.state !== "login-secondary" &&
          this.props.state !== "login-home" &&
          this.props.state !== "reset-password" &&
          this.props.state !== "post-login" &&
          this.renderTopbarMenu()}
      </div>
    );
  }
}

export default GametizeTopbar;

import React from "react";

import { PROJECT_HOME } from "App/Routes";
import Link from "components/shared/Link/Link";

import Dotdotdot from "react-dotdotdot";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import MyProjectCard from "components/ProjectsList/MyProjectCard";
import urlServices from "services/urlServices";

class GametizeMyProjectCard extends MyProjectCard {
  render() {
    const link = PROJECT_HOME.format(this.props.id);
    let description = this.props.description;
    let img = this.props.img;
    let title = this.props.title;

    return (
      <div className="project-card-container">
        {this.props.showUnjoinProjectDialog && this.renderUnjoinProjectDialog()}
        {this.props.showShareGameDialog && this.renderShareGameDialog()}
        <Link
          to={
            link +
            "?prevUrl=" +
            urlServices.removeFirstSlash(window.location.pathname)
          }
          className="project-card project-card-bg pure-g"
          onClick={this.props.handleProjectCardClick}
        >
          <div className="pure-u-5-24">
            <span className="square-image-wrapper">
              <span className="square-image circle">
                <img src={img} alt={title} />
              </span>
            </span>
          </div>
          <div className="pure-u-1-24" />
          {/*
            for description and title, dedicate 5/8 of space instead of 3/4,
            to prevent overlapping of 'delete project' button and text
          */}
          <div className="pure-u-18-24 my-project-info">
            <div className="my-project-card-header">
              <Dotdotdot clamp={2} className="my-project-card-title">
                <h6 className="title">{title}</h6>
              </Dotdotdot>
              {this.renderMenuDropdownButton()}
            </div>
            {description && description.trim() !== "" && (
              <div className="my-description-content description-content">
                <div className="hide-from-md">
                  <Dotdotdot clamp={5}>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                  </Dotdotdot>
                </div>
                {/* Show more lines in desktop mode */}
                <div className="hide-below-md">
                  <Dotdotdot clamp={6}>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                  </Dotdotdot>
                </div>
              </div>
            )}
          </div>
        </Link>
      </div>
    );
  }
}

export default GametizeMyProjectCard;

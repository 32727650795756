import React from "react";
import { connect } from "react-redux";
import {
  ProjectsListContainer,
  mapStateToProps,
} from "components/ProjectsList/ProjectsListContainer";

import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import FeaturedProjectsPage from "./FeaturedProjectsPage";

import localize from "lang/localize";

class FeaturedProjectsContainer extends ProjectsListContainer {
  render() {
    if (this.state.projects && this.state.projects.length !== 0) {
      return (
        <FeaturedProjectsPage
          projects={this.state.projects}
          more={this.state.more}
          handleMore={() => this.handleMore()}
          language={this.props.language}
          isLoadingMore={this.state.isLoadingMore}
        />
      );
    } else if (this.state.projects) {
      return (
        <GenericErrorPage
          message={localize("empty_bundles_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps)(FeaturedProjectsContainer);

import React, { Component } from "react";
import PropTypes from "prop-types";

import "./AchievementsSlider.css";

import Slider from "react-slick";
import urlParse from "library/js/url";

const propTypes = {
  achievements: PropTypes.array.isRequired,
};

/**
 * Slider View for Achievements and Rewards Display
 */
class AchievementsSlider extends Component {
  /**
   * Retrieve achievement or reward image.
   *
   * @param {Object} item
   * @returns
   */
  getItemImage(item) {
    let name = "";

    if (item.imageLarge) {
      // Map to achievement param
      name = item.imageLarge;
    } else if (item.rewardImageLarge) {
      // Map to reward param
      name = item.rewardImageLarge;
    }

    return name;
  }

  /**
   * Retrieve achievement or reward title.
   *
   * @param {Object} item
   * @returns
   */
  getItemName(item) {
    let name = "";

    if (item.name) {
      // Map to achievement param
      name = item.name;
    } else if (item.rewardName) {
      // Map to reward param
      name = item.rewardName;
    }

    return name;
  }

  /**
   * Retrieve achievement or reward description.
   *
   * @param {Object} item
   * @returns
   */
  getItemDescription(item) {
    let description = "";

    if (item.descriptionAfter) {
      // Map to achievement param
      description = item.descriptionAfter;
    } else if (item.rewardName) {
      // Map to reward param
      description = item.rewardDescription;
    }

    return description;
  }

  /**
   * Render achievement or reward title.
   *
   * @param {Object} item
   * @returns
   */
  renderItemTitle(item) {
    if (!item) {
      return null;
    }

    let name = this.getItemName(item);

    if (name.trim() !== "") {
      return (
        <h6 className="achievementTitle bottommargin-md text-align-center bottommargin-20">
          {name}
        </h6>
      );
    } else {
      return null;
    }
  }

  /**
   * Render achievement or reward image.
   *
   * @param {Object} item
   * @returns
   */
  renderItemImg(item) {
    if (!item) {
      return null;
    }

    let imageLarge = this.getItemImage(item);
    let name = this.getItemName(item);

    if (imageLarge.trim() !== "") {
      return (
        <div className="achievement-image-container">
          <img src={imageLarge} alt={name} />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Add anchor text links
   *
   * @param {string} text - Text
   */
  addAnchors(text) {
    // add anchor tags to links
    if (text) {
    }
    return urlParse(text, true);
  }

  /**
   * Render achievement or reward description.
   *
   * @param {Object} item
   * @returns
   */
  renderItemText(item) {
    let description = this.getItemDescription(item);

    let descriptionAnchored = this.addAnchors(description);
    if (description.trim() !== "") {
      return (
        <div>
          <p
            className="achievement-description responsive-img-description text-align-center"
            dangerouslySetInnerHTML={{ __html: descriptionAnchored }}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render the component
   */
  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      swipeToSlide: this.props.achievements.length > 1,
      swipe: this.props.achievements.length > 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      className:
        this.props.achievements.length > 1
          ? "achievementsSlider"
          : "achievementsSlider oneItem",
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      adaptiveHeight: false,
    };
    return (
      <Slider {...settings}>
        {this.props.achievements.map((achievement, index) => {
          return (
            <div key={index}>
              {this.renderItemTitle(achievement)}
              {this.renderItemImg(achievement)}
              {this.renderItemText(achievement)}
            </div>
          );
        })}
      </Slider>
    );
  }
}

/**
 * Render the next arrow for the achievement slider.
 *
 * @param {*} props
 * @returns
 */
function NextArrow(props) {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

/**
 * Render the next arrow for the achievement slider.
 *
 * @param {*} props
 * @returns
 */
function PrevArrow(props) {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

AchievementsSlider.propTypes = propTypes;

export default AchievementsSlider;

import DownloadAppAlert from "components/shared/Alert/DownloadAppAlert.js";
import "./Alert.scss";

const GametizeDownloadAppAlert = props => {
  const imageComponent = (
    <img className="gametize-icon" src="/favicon.ico" alt="gametize icon" />
  );

  return <DownloadAppAlert {...props} imageComponent={imageComponent} />;
};

export default GametizeDownloadAppAlert;

import React from "react";

import { PROJECT_HOME } from "App/Routes";
import placeholder_project from "images/core/placeholders/placeholder_project.png";
import Link from "components/shared/Link/Link";

import Dotdotdot from "react-dotdotdot";

import FeaturedProjectCard from "components/ProjectsList/FeaturedProjectCard";
import urlServices from "services/urlServices";

class GametizeFeaturedProjectCard extends FeaturedProjectCard {
  render() {
    let description = this.props.description
      ? this.addAnchors(this.props.description)
      : "";
    let img = this.props.img ? this.props.img : placeholder_project;
    let text = this.props.text;

    return (
      <div className="project-cards-wrapper">
        <div className="project-card-container">
          <Link
            to={
              PROJECT_HOME.format(this.props.id) +
              "?prevUrl=" +
              urlServices.removeFirstSlash(window.location.pathname)
            }
            className="project-card featured-project-card project-card-bg featured-project-card-bg pure-g"
            style={{
              backgroundImage: "url(" + img + ")",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          >
            <div className="title-wrapper title text-shadow">
              <Dotdotdot clamp={1}>
                <p>{text}</p>
              </Dotdotdot>
            </div>
            <div className="relative">
              {this.renderProjectShareButton()}
              {this.props.description && description.trim() !== "" && (
                <div className="description-wrapper">
                  <Dotdotdot clamp={3}>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                  </Dotdotdot>
                </div>
              )}
            </div>
          </Link>
          {this.state.showShareGameDialog && this.renderShareGameDialog()}
        </div>
      </div>
    );
  }
}

export default GametizeFeaturedProjectCard;

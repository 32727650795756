import React from "react";
import { connect } from "react-redux";

import LeaderboardPage from "components/Project/Leaderboard/LeaderboardPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import {
  LeaderboardContainer,
  mapStateToProps,
  mapDispatchToProps,
} from "components/Project/Leaderboard/LeaderboardContainer";

import localize from "lang/localize";

export class GametizeLeaderboardContainer extends LeaderboardContainer {
  async componentDidMount() {
    if (this.props.sessionKey) {
      this.getPageData();
    }

    // populate project in redux store
    if (this.props.projectId) {
      await this.getProject();

      if (this.props.project.teamEnabled) {
        //set default leaderboard to team if enabled
        this.setState({ currentBoard: "team" }, () => {
          this.getPageTeamData();
        });
      }

      // If project is public, allow view of leaderboard even if there is no session
      if (!this.props.sessionKey) {
        this.getPageData();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sessionKey !== this.props.sessionKey) {
      this.getPageData();
    }
    //TODO: handling of public leaderboard?

    /* checks for team leaderboard whenever project id changes */
    if (
      prevProps.project.id !== this.props.project.id &&
      this.props.project.teamEnabled &&
      this.state.currentBoard === "team"
    ) {
      this.getPageTeamData();
    }
  }

  render() {
    if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (
      (this.state.indivReqStatus.indexOf("overall") >= 0 ||
        this.state.teamReqStatus.indexOf("overall") >= 0) &&
      this.props.project.teamEnabled !== undefined
    ) {
      /* Retrieving individual/player overall leaderboard is the fastest */
      /* Default view is also individual/player overall leaderboard */
      /* If can't even retrieve it, send to loading screen */

      return (
        <LeaderboardPage
          projectId={this.props.projectId}
          mainBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].mainBoard
              : null
          }
          userBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].userBoard
              : null
          }
          moreBoardData={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type]
                  .moreBoardData
              : null
          }
          fullBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].data
              : null
          }
          more={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].more
              : null
          }
          topicLeaderboards={this.state.topicLeaderboards}
          type={this.state.type}
          board={this.state.currentBoard}
          teamAvailable={this.props.project.teamEnabled}
          handleToggleType={this.handleToggleType}
          handleToggleBoard={this.handleToggleBoard}
          handleToggleTopic={this.handleToggleTopic}
          handleToggleOverview={this.handleToggleOverview}
          topicId={this.state.selectedTopicLeaderboard}
          handleFetchData={this.handleFetchData}
          overview={this.state.overview}
          userId={this.props.user.id}
          teamId={this.props.team.id}
          indivReqStatus={this.state.indivReqStatus}
          teamReqStatus={this.state.indivReqStatus}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GametizeLeaderboardContainer);

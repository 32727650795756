import React, { Component } from "react";
import PropTypes from "prop-types";

import Link from "components/shared/Link/Link";

import FacebookLogin from "react-facebook-login";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

import {
  FACEBOOK_APP_ID,
  GOOGLE_APP_ID,
  ENABLE_LOGIN_HOME,
  ENABLE_FACEBOOK_LOGIN,
  ENABLE_GOOGLE_LOGIN,
  ENABLE_MICROSOFT_LOGIN,
  ENABLE_LOGIN_TOPBAR
} from "config";
import localize from "lang/localize";
import urlServices from "services/urlServices";
import deviceServices from "services/deviceServices";

import "./Login.css";

const propTypes = {
  topbarlessBackButtonRoute: PropTypes.string,
  language: PropTypes.string,
  /* OAuth */
  responseFacebook: PropTypes.func.isRequired,
  responseFacebookError: PropTypes.func.isRequired,
  responseGoogle: PropTypes.func.isRequired,
  responseGoogleError: PropTypes.func.isRequired,
  responseMicrosoft: PropTypes.func.isRequired
};

class LoginSSOPage extends Component {
  constructor() {
    super();
    this.recaptchaRef = React.createRef();
  }

  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("loginPage")) {
      bodyDOM.className += " loginPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.className += " page-not-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("loginPage")) {
      bodyDOM.classList.remove("loginPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.classList.remove("page-not-loggedin");
    }
  }

  renderTopbarlessBackButton() {
    if (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR) {
      return (
        <div className="pure-u-1-1 box-sizing-border-box-all">
          <Link
            className="topbarless-back-button"
            to={this.props.topbarlessBackButtonRoute}
          >
            <i className="fas fa-arrow-left" />
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }

  renderFacebookLoginButton() {
    if (ENABLE_FACEBOOK_LOGIN) {
      return (
        <div className="pure-u-1-1 horizontalpadding-5 box-sizing-border-box-all">
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            scope="email"
            version="12.0"
            callback={this.props.responseFacebook}
            onFailure={this.props.responseFacebookError}
            cssClass={"button button-fb"}
            textButton={""}
            icon="fas fa-facebook"
            isMobile={false}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderGoogleLoginButton() {
    if (ENABLE_GOOGLE_LOGIN) {
      return (
        <div className="pure-u-1-1 horizontalpadding-5">
          <GoogleOAuthProvider clientId={GOOGLE_APP_ID}>
            <GoogleLogin
              onSuccess={this.props.responseGoogle}
              onError={this.props.responseGoogleError}
              type="icon"
              shape="circle"
            />
          </GoogleOAuthProvider>
        </div>
      );
    } else {
      return null;
    }
  }

  renderMicrosoftLoginButton() {
    if (ENABLE_MICROSOFT_LOGIN) {
      return (
        <div className="pure-u-1-1 horizontalpadding-5 box-sizing-border-box-all">
          <button
            id="msLoginButton"
            className="button button-microsoft"
            type="button"
            onClick={this.props.responseMicrosoft}
          >
            <i className="button-microsoft-icon" />
          </button>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="login-register-container container verticalpadding">
        <div
          className={
            "innerblock pure-g" +
            (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR ? " topmargin-20" : "")
          }
        >
          {this.renderTopbarlessBackButton()}
          <div className="pure-u-sm-4-24 pure-u-md-6-24" />
          <div className="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2">
            <div className="pure-g">
              <div className="pure-u-1-1">
                <p className="bottommargin textcenter box-sizing-border-box-all">
                  <strong>
                    {localize("login_view_sign_in_start", this.props.language)}
                  </strong>
                </p>
              </div>
              {/* Add hidesso=true to query string in order to hide sso login, e.g. /login?hidesso=true */}
              {urlServices.getUrlParamValueByKey("hidesso") !== "true" &&
                !deviceServices.isWebView() && (
                  <div className="button-group-social-login">
                    {this.renderFacebookLoginButton()}
                    {this.renderGoogleLoginButton()}
                    {this.renderMicrosoftLoginButton()}
                  </div>
                )}
            </div>
          </div>
          <div className="pure-u-sm-4-24 pure-u-md-6-24" />
        </div>
      </div>
    );
  }
}

LoginSSOPage.propTypes = propTypes;

export default LoginSSOPage;

import React, { Component } from "react";
import PropTypes from "prop-types";

import BasicShareDialog from "components/shared/Dialogs/ShareDialog/BasicShareDialog";
import urlServices from "services/urlServices";
import "./PersonalityModal.css";

import { TOPIC } from "App/Routes";
import Modal from "react-modal";
import localize from "lang/localize";

import { PLAYER_URL } from "config";

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isShareDialogOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  personality: PropTypes.object.isRequired,
  topicId: PropTypes.number,
  handleOpenShareDialog: PropTypes.func.isRequired,
  handleCloseShareDialog: PropTypes.func.isRequired,
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class PersonalityModal extends Component {
  renderCloseButton() {
    return (
      <div>
        <button
          onClick={() => {
            this.props.onRequestClose();
          }}
          className="close-button"
        >
          <i className="fa close-icon" aria-hidden="true" />
        </button>
      </div>
    );
  }

  /**
   * Render Share Dialog
   */
  renderShareDialog() {
    // Share topic URL
    const topicPage = this.props.topicId
      ? PLAYER_URL +
        urlServices.removeFirstSlash(TOPIC.format(this.props.topicId))
      : null;
    // Sharable link is from API
    const sharableLink = this.props.personality.tagShareLink;
    const personalityImage = this.props.personality
      ? this.props.personality.image
      : "";

    return (
      <BasicShareDialog
        showDialog={this.props.isShareDialogOpen}
        handleCloseDialog={this.props.handleCloseShareDialog}
        sharableLink={sharableLink}
        pageUrl={topicPage}
        imageUrl={personalityImage}
        language={this.props.language}
      />
    );
  }

  render() {
    let personalityTag = this.props.personality
      ? this.props.personality.tag
      : "";
    let personalityImage = this.props.personality
      ? this.props.personality.image
      : "";
    let personalityDescription = this.props.personality
      ? this.props.personality.description
      : "";

    return (
      <Modal
        isOpen={this.props.isOpen}
        contentLabel=""
        onRequestClose={this.props.onRequestClose}
        shouldCloseOnOverlayClick={false}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        {this.renderCloseButton()}

        <div className="modal-body textcenter">
          <div className="personality-modal-wrapper">
            {this.props.personality.tag && (
              <div>
                <h3>
                  {localize("tag_result_text", this.props.language) + ": "}
                </h3>
                <h4>
                  <span>{personalityTag}</span>
                </h4>
              </div>
            )}
            {this.props.personality.image && (
              <p>
                <img src={personalityImage} alt={personalityTag} />
              </p>
            )}
            {this.props.personality.description && (
              <p>{personalityDescription}</p>
            )}

            {this.props.personality.tagShareLink && (
              <p>
                <button
                  className="button cta automargin"
                  onClick={this.props.handleOpenShareDialog}
                >
                  <span className="fas fa-share-alt rightmargin-10"></span>{" "}
                  {localize("share_text", this.props.language)}
                </button>
              </p>
            )}
            {this.props.personality.tagShareLink &&
              this.props.isShareDialogOpen &&
              this.renderShareDialog()}

            {/* Download result image if available */}
            {this.props.personality.image && (
              <p>
                <a
                  href={personalityImage}
                  target="_blank"
                  className="button link active inline"
                  rel="noreferrer"
                  download
                >
                  <span className="fas fa-download rightmargin-10"></span>{" "}
                  {localize("button_download", this.props.language)}
                </a>
              </p>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

PersonalityModal.propTypes = propTypes;

export default PersonalityModal;
